<template>
  <div class="data-analysis-history">
    <div class="data-analysis-history-header">
      <div class="back_box">
        <span class="back" @click="goBack"
          ><img
            :src="require('assets/images/goldenSentence/back.png')"
            alt=""
          />返回</span
        >

        <div class="baogao">历史分析报告</div>

        <div class="History" @click="getList">
          <img :src="require('assets/images/refresh11.png')" alt="" />
          刷新
        </div>
      </div>
    </div>
    <div class="data-analysis-history-table">
      <el-table
        width="100%"
        :data="dataList"
        :show-header="false"
        @row-click="clickTr"
      >
        <!--标题-->
        <el-table-column>
          <template slot-scope="{ row: { name, reportUrl }, $index }">
            <div class="states" :class="reportUrl ? 'green' : 'orange'">
              {{ reportUrl ? "完成" : "未完成" }}
            </div>
            <div class="title">{{ `${$index + 1}.${name}` }}</div>
          </template>
        </el-table-column>
        <!--时间-->
        <el-table-column>
          <template slot-scope="{ row: { createTime } }">
            <div class="time">{{ createTime }}</div>
          </template>
        </el-table-column>
        <!--下载-->
        <el-table-column width="80">
          <template slot-scope="{ row: { name, reportUrl } }">
            <div
              class="down"
              v-if="reportUrl"
              @click.stop="check(name, reportUrl)"
            >
              <i class="down-icon"></i>
              <span class="txt">查看</span>
            </div>
          </template>
        </el-table-column>
        <!--占位格-->
        <el-table-column width="40" />
        <!--删除-->
        <el-table-column width="80">
          <template slot-scope="{ row: { id } }">
            <div class="delete" @click.stop="clikcDelete(id)">
              <i class="delete-icon"></i>
              <span class="txt">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        layout="total, prev, pager, next, jumper"
        :page-sizes="[15, 20, 25, 30]"
        :pager-count="5"
        :current-page="pageNum"
        :page-size="limit"
        @current-change="currentChange"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { AnalysisList, DataDelete } from "api/infoCompile";
import { previewUrl } from "api/common";
import qs from "qs";
export default {
  name: "DataAnalysisHistory",
  components: {},
  data() {
    return {
      pageNum: 1,
      dataList: [],
      total: 0,
      limit: 10,
    };
  },
  methods: {
    clickTr(row, column, event) {
      try {
        // eslint-disable-next-line
        DownloadDocument(
          row.name + ".pdf",
          this.$store.state.onlineBasePath + row.url
        );
      } catch (e) {
        console.log(e);
      }
      // this.$router.push({
      //   name: "DataAnalysiSeport",
      //   query: {
      //     id: row.id,
      //   },
      // });
    },
    // 查看
    check(name, url) {
      window.ShowDownLoadTips();
      // try {
      //   // eslint-disable-next-line
      //   DownloadDocument(name + ".pdf", this.$store.state.onlineBasePath + url);
      // } catch (e) {
      //   console.log(e);
      // }

      // previewUrl(this.$store.state.onlineBasePath + url)
      // previewUrl({
      //   url: this.$store.state.onlineBasePath + url,
      // }).then((res) => {
      //   window.open(res.data);
      // });
    },

    // page改变触发
    currentChange(cur) {
      this.pageNum = cur;
      this.getList();
    },
    //返回
    goBack() {
      this.$router.push({
        name: "DataAnalysisIndex",
      });
    },
    // 删除
    clikcDelete(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let form = new FormData();
          form.append("analysisId", id);
          const res = await DataDelete(form);
          if (res.code == 200) {
            this.getList();
            this.$message("删除成功");
          } else {
            this.$message(res.message);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 获取历史报告
    async getList() {
      const data = {
        page: this.pageNum,
        limit: 10,
      };
      const res = await AnalysisList(qs.stringify(data));
      if (res.code == 200) {
        this.total = res.data.total;

        this.dataList = res.data.records;
      } else {
        this.$message.error(res.message);
      }
    },
  },

  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.states {
  position: absolute;
  left: 30px;
  top: 50%;
  width: 50px;
  height: 18px;
  margin-top: -9px;
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  line-height: 18px;
  color: #fff;
  .green {
    &::before {
      background: url("~assets/images/rhombus-green.png") no-repeat;
    }
  }
  .orange {
    &::before {
      background: url("~assets/images/rhombus-orange.png") no-repeat;
    }
  }
}

.data-analysis-history {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  height: 500px;
  // height: 100vh;
  padding: 0 0 20px;
  box-sizing: border-box;
  width: 100%;
  &-header {
    margin-top: 14px;
    width: 100%;
    .back_box {
      font-size: 12px;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 20px;
      background-color: #f8f9fb;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .back {
      @include noselect;
      display: inline-block;

      img {
        width: 6px;
        height: 9px;
        margin-right: 6px;
        vertical-align: middle;
      }
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      cursor: pointer;
    }

    .History {
      cursor: pointer;

      align-items: center;
      font-size: 12px;
      display: inline-block;
      text-align: right;

      // display: flex;
      // align-items: center;
      // justify-content: center;
      img {
        // margin-right: 5px;
        vertical-align: top;
        width: 13px;
        height: 13px;
      }
    }
  }
  &-table {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    flex: 1;
    margin-top: 20px;
    ::v-deep .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    ::v-deep .el-table::before {
      background-color: transparent;
    }
    ::v-deep .el-table tr.el-table__row {
      td:first-child {
        .cell {
          padding-left: 0;
        }
      }
      td:last-child {
        .cell {
          display: flex;
          justify-content: flex-end;
          padding-right: 5px;
        }
      }
    }
    ::v-deep .el-table tr.el-table__row:hover {
      .cell {
        .title {
          color: #4587ff;
        }
        .down {
          color: #4587ff;
          &-icon {
            &::before {
              background: url("~assets/images/goldenSentence/analysis-blue.png")
                100% no-repeat;
            }
          }
        }
        .delete {
          color: #4587ff;
          &-icon {
            &::before {
              background: url("~assets/images/goldenSentence/analysis-delete-blue.png")
                100% no-repeat;
            }
          }
        }
      }
    }
    ::v-deep .el-table td.el-table__cell {
      &:first-child {
        @include noselect;
      }
      border-bottom: none;
      .cell {
        @include ellipsis;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .down,
        .delete {
          @include flex-start;
          @include noselect;
          font-weight: 500;
          cursor: pointer;
          &-icon {
            position: relative;
            width: 15px;
            height: 15px;
            &::before {
              content: "";
              position: absolute;
              width: 15px;
              height: 15px;
            }
          }
          .txt {
            margin-left: 5px;
          }
        }
        .down {
          &-icon {
            &::before {
              background: url("~assets/images/goldenSentence/analysis.png") 100%
                no-repeat;
            }
          }
        }
        .delete {
          &-icon {
            &::before {
              background: url("~assets/images/goldenSentence/analysis-delete.png")
                100% no-repeat;
            }
          }
        }
      }

      &:first-child {
        @include noselect;

        .cell {
          overflow: visible;
        }
      }

      &:last-child {
        overflow: visible;

        .cell {
          overflow: visible;
        }
      }

      .cell {
        @include ellipsis;
        position: relative;

        .states {
          position: absolute;
          left: 190px;
          top: 50%;
          width: 50px;
          height: 18px;
          margin-top: -9px;
          font-size: 12px;
          font-weight: 800;
          text-align: center;
          line-height: 18px;
          color: #fff;
          z-index: 1;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: -1;
          }
        }

        .gray {
          &::before {
            background: url("~assets/images/rhombus-gray.png") no-repeat;
          }

          color: #999;
        }

        .green {
          &::before {
            background: url("~assets/images/rhombus-green.png") no-repeat;
          }
        }

        .orange {
          &::before {
            background: url("~assets/images/rhombus-orange.png") no-repeat;
          }
        }

        .blue {
          &::before {
            background: url("~assets/images/rhombus-blue.png") no-repeat;
          }
        }

        .evaluate-wrap {
          @include noselect;
          font-size: 12px;
          font-weight: 500;

          .nothing {
            color: #999;
          }

          .original,
          .homology {
            @include flex-start;

            .levelIcon {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              vertical-align: middle;
            }

            .txt {
              margin-left: 5px;
              color: #4587ff;
            }
          }

          .homology {
            .txt {
              &.is-homology {
                color: #e15240;
              }

              &.no-homology {
                color: #4587ff;
              }
            }
          }
        }

        .authorize {
          font-size: 12px;
          font-weight: 500;
          cursor: pointer;
        }

        .is-blue {
          color: #4587ff;
          cursor: pointer;
        }

        .is-gray {
          color: #999999;
        }

        .operate {
          @include flex-between;

          span {
            @include flex-start;
            cursor: pointer;

            i {
              position: relative;
              width: 16px;
              height: 16px;
            }
          }

          .more .submenu {
            width: 114px;
          }

          .more,
          .down {
            .submenu {
              @include noselect;

              &.submenu-active {
                @include flex-center(column);
                justify-content: normal;
                align-items: normal;
              }

              display: none;
              position: absolute;
              z-index: 1000;
              top: 20px;
              left: -75px;
              width: 99px;
              padding: 15px 0;
              background: #ffffff;
              box-shadow: 0px 4px 8px 1px rgba(31, 34, 40, 0.17);
              border-radius: 5px;
              box-sizing: border-box;

              span {
                padding: 0 15px;
                font-size: 12px;
                font-weight: 500;
                color: #999999;
                line-height: 1;
                cursor: pointer;

                &:hover {
                  color: #333333;
                }

                &:first-child ~ span {
                  margin-top: 20px;
                }
              }
            }
          }

          .more i,
          .view i,
          .down i,
          .share i,
          .delete i {
            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              background-size: 100% 100%;
            }
          }

          .more i::before {
            background: url("~assets/images/more.png") no-repeat;
          }

          .more:hover i::before {
            background: url("~assets/images/more-blue.png") no-repeat;
          }

          .view i::before {
            background: url("~assets/images/report-view-gray.png") no-repeat;
          }

          .view:hover i::before {
            background: url("~assets/images/report-view-blue.png") no-repeat;
          }

          .down i::before {
            background: url("~assets/images/report-down-gray.png") no-repeat;
          }

          .down:hover i::before {
            background: url("~assets/images/report-down-blue.png") no-repeat;
          }

          .share i::before {
            background: url("~assets/images/report-share-gray.png") no-repeat;
          }

          .share:hover i::before {
            background: url("~assets/images/report-share-blue.png") no-repeat;
          }

          .delete i::before {
            background: url("~assets/images/delete.png") no-repeat;
          }

          .delete:hover i::before {
            background: url("~assets/images/delete-blue.png") no-repeat;
          }
        }
      }
    }
  }
  &-footer {
    @include flex-center;
    ::v-deep .el-pagination {
      button {
        width: 80px;
      }
    }
  }

  .page {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
