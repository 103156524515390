var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-analysis-history"},[_c('div',{staticClass:"data-analysis-history-header"},[_c('div',{staticClass:"back_box"},[_c('span',{staticClass:"back",on:{"click":_vm.goBack}},[_c('img',{attrs:{"src":require('assets/images/goldenSentence/back.png'),"alt":""}}),_vm._v("返回")]),_c('div',{staticClass:"baogao"},[_vm._v("历史分析报告")]),_c('div',{staticClass:"History",on:{"click":_vm.getList}},[_c('img',{attrs:{"src":require('assets/images/refresh11.png'),"alt":""}}),_vm._v(" 刷新 ")])])]),_c('div',{staticClass:"data-analysis-history-table"},[_c('el-table',{attrs:{"width":"100%","data":_vm.dataList,"show-header":false},on:{"row-click":_vm.clickTr}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var name = ref_row.name;
var reportUrl = ref_row.reportUrl;
var $index = ref.$index;
return [_c('div',{staticClass:"states",class:reportUrl ? 'green' : 'orange'},[_vm._v(" "+_vm._s(reportUrl ? "完成" : "未完成")+" ")]),_c('div',{staticClass:"title"},[_vm._v(_vm._s((($index + 1) + "." + name)))])]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var createTime = ref.row.createTime;
return [_c('div',{staticClass:"time"},[_vm._v(_vm._s(createTime))])]}}])}),_c('el-table-column',{attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var name = ref_row.name;
var reportUrl = ref_row.reportUrl;
return [(reportUrl)?_c('div',{staticClass:"down",on:{"click":function($event){$event.stopPropagation();return _vm.check(name, reportUrl)}}},[_c('i',{staticClass:"down-icon"}),_c('span',{staticClass:"txt"},[_vm._v("查看")])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"40"}}),_c('el-table-column',{attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var id = ref.row.id;
return [_c('div',{staticClass:"delete",on:{"click":function($event){$event.stopPropagation();return _vm.clikcDelete(id)}}},[_c('i',{staticClass:"delete-icon"}),_c('span',{staticClass:"txt"},[_vm._v("删除")])])]}}])})],1)],1),_c('div',{staticClass:"page"},[_c('el-pagination',{attrs:{"layout":"total, prev, pager, next, jumper","page-sizes":[15, 20, 25, 30],"pager-count":5,"current-page":_vm.pageNum,"page-size":_vm.limit,"total":_vm.total},on:{"current-change":_vm.currentChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }